import React from 'react'
import Layout from '../components/layout'
import studyLogo from "../assets/images/study-logo.png"

const convertMoneyInCentsToDollarText = (centsStr) => {
	let cents = parseInt(centsStr);
	if (!cents) return "n/a";
	return (cents / 100).toLocaleString("en-US", {style:"currency", currency:"USD"});
}

export default function PaymentReceiptTemplate({data, pageContext, location}) {
	const {header, footer} = pageContext
	if (typeof window === "undefined") {
		return null;
	}
	// get url params
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const email = urlParams.get('email') ? urlParams.get('email') : 'n/a';
	const plan = urlParams.get('plan') ? urlParams.get('plan') : 'n/a';
	const order = urlParams.get('order') ? urlParams.get('order') : 'n/a';
	const amount = urlParams.get('amount') ? convertMoneyInCentsToDollarText(urlParams.get('amount')) : 'n/a';
	const date = urlParams.get('date') ? urlParams.get('date') : 'n/a';
	const type = urlParams.get('type') ? urlParams.get('type') : 'n/a';

	return (
	 <Layout sitemap={true} header={header} footer={footer}>
		 <div className="payment-container">
			 <div className="payment-container__fluid">
				 <h1 className="payment-page-title">Thank you for your order</h1>
				 <p className="payment-page-subTitle">You will receive a confirmation for your order to <span className="email">{email}</span></p>
				 <div className="receipt">
					 <p className="your-order-summary">Your order summary</p>
					 <h2 className="plan-name">{plan}</h2>

					 <div className="receipt__details">
						 <div className="flex-order-info">
							 <div>Order #:</div>
							 <div className="receipt__values">{order}</div>
						 </div>
						 <div className="flex-order-info">
							 <div>Amount charged:</div>
							 <div className="receipt__values">{amount}</div>
						 </div>
						 <div className="flex-order-info">
							 <div>Date of purchase:</div>
							 <div className="receipt__values">{date}</div>
						 </div>
						 <div className="flex-order-info">
							 <div>Payment type:</div>
							 <div className="receipt__values">{type}</div>
						 </div>
					 </div>

					 <p className="your-advisor-will-reach-out">Your advisor will reach out within the next 48 hours to discuss next steps. If you have questions before then,
						 please reach out to <a href="mailto:advisor@enhancedprep.com">advisor@enhancedprep.com</a> for help.</p>
		 		 </div>
				 <div className="powered-by">
					 <div className="powered-by__text">
						 <p className="powered-by__text-title">Technology that fuels tutoring</p>
						 <p className="powered-by__text-text">We&apos;ve partnered with Study.com to take tutoring to the next level. Students can now schedule tutoring sessions,
							 do homework, and more through Study.com&apos;s learning platform.
						 </p>
					 </div>
					 <div className="powered-by__logo">
						 <img src={studyLogo} alt='study logo' draggable={false}/>
					 </div>
				 </div>
			 </div>
		 </div>
	 </Layout>
	)
}